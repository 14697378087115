tr.deactive {
  background-color: #ffd9dd;
}

.list_name {
  min-width: 90px;
}

.activedeactiveicon {
  font-size: 18px;
  line-height: normal;
  vertical-align: middle;
}

.profiledetailscls {
  background: #f2f2f2;
  padding: 8px 16px;
  border-radius: 50px;
  font-size: 14px;
}

.mobilalnumber {
  width: 100%;
  position: absolute;
  left: 104px;
  padding: 3px !important;
  border-left: none !important;
  border: none !important;
  height: 35px;
  margin-top: 2px;
}

.mobilalnumber:focus {
  width: 100%;
  position: absolute;
  left: 104px;
  padding: 0px !important;
  border-left: none !important;
}

.react-tel-input .form-control:focus {
  position: relative;
  font-size: 14px;
  letter-spacing: 0.01rem;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 48px;
  margin-left: 0;
  background: #fff !important;
  border: 1px solid #cacaca;
  border-radius: 5px;
  line-height: 25px;
  height: 38px;
}

.react-tel-input .form-control {
  position: relative;
  font-size: 14px;
  letter-spacing: 0.01rem;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 48px;
  margin-left: 0;
  background: #fff !important;
  border: 1px solid #cacaca;
  border-radius: 5px;
  line-height: 25px;
  height: 38px;
  width: 100% !important;
  outline: none;
}

// img.rounded-circle.mr-1.prop_img {
//     width: 37px;
//     height: 37px;
// }

.nowrap {
  white-space: nowrap;
}

.modal-dialog-md {
  max-width: 900px !important;
}
.loadstabs .nav-link.active {
  background: #494f9f !important;
  color: #fff !important;
}
.rangetabs {
  border-bottom: 0;
  border: 1px solid #cbc1c1;
  border-radius: 4px;
  background: #fff;
  // width: 100%;
}
.rangetabs .nav-item {
  margin-bottom: 0;
}
.rangetabs .nav-link.active {
  background-color: #494f9f;
  border-color: #dee2e6 #dee2e6 #fff;
  border: 0;
  font-size: 13px;
  border-radius: 4px;
  margin: 2px;
}
.rangetabs .nav-link {
  font-size: 12px;
  background: #80808057;
  border-radius: 0;
  background: 0 0;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  margin: 2px 0px;
  padding: 0.5rem 7px;
  font-size: 11px !important;
}

.rangetabs li.nav-item svg {
  font-size: 18px;
}
.rangetabs li.nav-item span {
  margin-left: 2px;
}
.rangeflex {
  width: 215px;
}
.rangeflex label {
  white-space: nowrap;
  padding-top: 21px;
  padding-right: 10px;
}
.rangeflex .range_price {
  font-size: 10px;
}
.read_more_table {
  color: #000;
  font-size: 15px;
}
.read_more_table:hover {
  color: #000;
  text-decoration: none;
}
.info_i {
  cursor: pointer;
  display: flex;
  justify-content: end;
  max-width: 117px;
  padding-top: 3px;
}
.ant-tooltip-content ul {
  margin-bottom: 3px !important;
}
.ant-tooltip-content li {
  font-size: 13px !important;
}

@media (min-width: 1218px) {
  .rangetabs {
    height: 40px;
  }
}
